@layer bootstrap, primeng, app;

/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import '~viewerjs/dist/viewer.css';
@import 'styles/button';
@import 'styles/table';
@import 'styles/file-upload';

@import 'bootstrap/dist/css/bootstrap.css' layer(bootstrap);
@import 'quill/dist/quill.snow.css';
@import 'primeicons/primeicons.css';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
:root {
    --bs-body-font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
        'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
    --font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-feature-settings: normal;
}

html {
    --app-timeline-border-color-danger: #dc3545;
    --app-timeline-border-color-success: #198754;
    --app-timeline-border-color-grey: #767676;
}

@layer bootstrap {
    a {
        color: #0366d6;
    }
}

.p-dropdown,
.p-component {
    line-height: initial;
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.cursor-pointer {
    cursor: pointer !important;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.title-s {
    font-size: 18px !important;
    line-height: 1.2;
}

.p-column-filter-buttonbar {
    display: none !important;
}

.p-column-filter-menu-button {
    color: inherit !important;
}

.p-sortable-column-icon {
    margin-left: 0.5rem;
}

.p-input-group-sm {
    height: 40px;
}

p-editor {
    display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;

    .p-editor-container {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;

        .p-editor-toolbar {
        }

        .p-editor-content {
            flex-grow: 1;
            height: auto;
            overflow: hidden;
        }
    }

    .ql-container {
        font-size: 1rem;
    }
}

.image-preview-dialog {
    .p-dialog-content {
        height: 80vh;
    }
}

.treatment-cell-delivery {
    &__wrapper {
        position: relative;
    }

    &__container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;

        app-treatment-deliver-column {
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-direction: column;

            & > div {
                flex-grow: 1;
            }
        }
    }
}

.disabled {
    pointer-events: none;
}

.p-selectbutton-sm {
    .p-button {
        font-size: 13px;
        font-weight: 400;
        padding: 8px 16px;
    }
}

.p-accordion-tab {
    .p-toggleable-content {
        overflow: hidden;
    }
}

.p-panel-toogleable.p-panel-expanded {
    .p-accordion-tab {
        .p-toggleable-content {
            overflow: hidden;
        }
    }
}

.p-panel-toggleable.p-panel-expanded {
    .p-accordion-tab {
        .p-toggleable-content:not(.ng-animating) {
            overflow: hidden;
        }
    }
}

.history-revisions__accordion,
.doctor-preferences__accordion {
    .p-accordion .p-accordion-header .p-accordion-header-link {
        font-size: 14px;
        padding: 12px 16px;
        font-weight: normal;
    }
}

@-webkit-keyframes flash {
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
@keyframes flash {
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
.animate__flash {
    -webkit-animation-duration: var(--animate-duration, 2s);
    animation-duration: var(--animate-duration, 2s);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: flash;
    animation-name: flash;
    animation-iteration-count: infinite;
}

.p-button-xs {
    padding: 6px 12px;
    font-size: 12px;
}

p-button[styleclass*='p-button-sm'] {
    button.p-button {
        @include button-sm();
    }
}

:focus-visible {
    outline: none;
}

.p-tooltip.treatment-design-note-tooltip {
    max-width: 80vw !important;
}

//.p-button.p-button-sm {
//    padding: 6px 12px;
//    font-size: 12px;
//}

p-calendar[selectionmode='range'] {
    .p-calendar .p-inputtext {
        width: 300px;
    }
}
